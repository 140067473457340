import * as jose from 'jose';
import { useState } from 'react';

import { useLiveCallback } from '../hooks/useLiveCallback';
import { getToken } from './getToken';

interface JwtToken {
  exp: number;
  iat: number;
  iss: string;
  sub: string;
}

export function getDecodedToken(): JwtToken | null {
  const token = getToken();
  if (token) {
    return jose.decodeJwt(token);
  }
  return null;
}

export function clearToken(): void {
  localStorage.removeItem('token');
}

function isTokenExpired(decodedToken: JwtToken) {
  return Date.now() > decodedToken.exp * 1000;
}

function isExpired(): boolean {
  const decodedToken = getDecodedToken();
  if (decodedToken) {
    return isTokenExpired(decodedToken);
  }
  return false;
}

export function isTokenValid(): boolean {
  const decodedToken = getDecodedToken();
  return !!decodedToken && !isTokenExpired(decodedToken);
}

type SaveTokenFunc = (token: string | null) => void;

export function useToken(
  checkExpired?: boolean
): [string | null, SaveTokenFunc] {
  const [token, setToken] = useState(getToken());
  if (token && checkExpired && isExpired()) {
    setToken(null);
  }

  const saveToken = useLiveCallback((_token: string | null) => {
    if (_token === null) {
      clearToken();
    } else {
      localStorage.setItem('token', _token);
    }
    setToken(_token);
  });

  return [token, saveToken];
}
