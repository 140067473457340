import {
  type EnumsNotificationFrequencyEmail,
  type EnumsNotificationFrequencySlack,
  type EnumsUserVesProfileKey,
  type ModelsMediaAsset,
} from '@lp-lib/api-service-client/public';
import { type RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import { type VideoEffectsSettingsUser } from '@lp-lib/game';
import { type ConnectionStatusMixin } from '@lp-lib/shared-schema';

import { uncheckedIndexAccess_UNSAFE } from '../utils/uncheckedIndexAccess_UNSAFE';
import { type Organizer } from './organization';

export enum ClientType {
  Host = 'host',
  Audience = 'audience',
}

export enum Role {
  User = 0,
  Admin = 10,
}

export interface BaseUser {
  id: string;
  username: string;
}

export interface User extends BaseUser {
  email: string | null;
  role: Role;
  venueActivated: boolean;
  organizer: Organizer | null;
  connectionTestStatus?: boolean | null;
  connectionTestUpdatedAt?: string | null;
}

export interface GroupSettings<T> {
  createdAt: string;
  updatedAt: string;
  value: T | null;
}

export type VideoEffectsSettingsProfiles = {
  [keyof in EnumsUserVesProfileKey]: VideoEffectsSettingsUser;
};

export interface UserSettings {
  privacy: GroupSettings<PrivacySettings> | null | undefined;
  notification: GroupSettings<NotificationSettings> | null | undefined;
  nps: GroupSettings<NpsSettings> | null | undefined;
  vbg: GroupSettings<VirtualBackgroundSettings> | null | undefined;
  i18n: GroupSettings<I18nSettings> | null | undefined;
  ves: GroupSettings<VideoEffectsSettingsProfiles> | null | undefined;
}

export type I18nSettings = {
  voiceOverLocale: string;
  subtitlesLocale: string;
  subtitles: boolean;
};

export type VirtualBackgroundSettings = {
  customBackgrounds: ModelsMediaAsset[];
};

export interface PrivacySettings {
  allowJoyCaptures: boolean | null;
}

export interface NpsSettings {
  ondAnsweredSessionId: string | null;
}

export interface NotificationSettings {
  analyticsDigestSlack: boolean | null;
  analyticsDigestEmail: boolean | null;
  disabled: boolean | null;
  slackFrequency: EnumsNotificationFrequencySlack | null;
  emailFrequency: EnumsNotificationFrequencyEmail | null;
}

export type AwayMessage = {
  teamId: string;
  reason: 'team' | 'host' | 'coordinator';
  byClientId: string;
  timestamp: number | RTDBServerValueTIMESTAMP;
  message?: string;
  redirectTo?: string;
};

export interface Participant extends BaseUser, ConnectionStatusMixin {
  clientId: string;
  clientType: ClientType;
  joinedAt: number;
  teamId?: string;
  orgId?: string;
  userAgent?: string;
  firstName?: string;
  lastName?: string;
  away?: AwayMessage;
  cohost?: boolean;
  icon?: string;
}

export type ParticipantFlags = {
  audio?: boolean;
  video?: boolean;
  lite?: boolean;
  hasMicrophone?: boolean;
  hasCamera?: boolean;

  onStage?: boolean;
  onStageMuted?: boolean;

  spectator?: boolean;
  voiceOverLocale?: string;
};

export type ParticipantFull = Participant & ParticipantFlags;

export type ParticipantMap = { [clientId: string]: Participant | undefined };
export type ParticipantFlagMap = {
  [clientId: string]: ParticipantFlags | undefined;
};

export type ParticipantFullMap = {
  [clientId: string]: ParticipantFull | undefined;
};

type ParticipantFlagKeys = keyof ParticipantFlags;
type ParticipantKeys = keyof Participant;

const participantFlagKeys = Object.keys({
  audio: true,
  video: true,
  lite: true,
  hasMicrophone: true,
  hasCamera: true,
  onStage: true,
  onStageMuted: true,
  spectator: true,
  voiceOverLocale: true,
} satisfies { [K in ParticipantFlagKeys]: true }) as ParticipantFlagKeys[];

const participantKeys = Object.keys({
  id: true,
  username: true,
  status: true,
  disconnectedAt: true,
  disconnectedReason: true,
  clientId: true,
  clientType: true,
  joinedAt: true,
  teamId: true,
  orgId: true,
  userAgent: true,
  firstName: true,
  lastName: true,
  away: true,
  icon: true,
  cohost: true,
} satisfies { [K in ParticipantKeys]: true }) as ParticipantKeys[];

export function intoParticipantFlags(
  p: Partial<ParticipantFull>
): ParticipantFlags {
  const flags = uncheckedIndexAccess_UNSAFE({});
  for (const key of participantFlagKeys) {
    if (key in p) flags[key] = p[key];
  }
  return flags as ParticipantFlags;
}

export function intoParticipantSkim(p: Partial<ParticipantFull>): Participant {
  const skim = uncheckedIndexAccess_UNSAFE({});
  for (const key of participantKeys) {
    if (key in p) skim[key] = p[key];
  }
  return skim as Participant;
}

export interface ChatParticipant {
  id: string;
  clientId: string;
  clientType: ClientType;
  username: string;
}

export const toChatParticipant = (p: Participant): ChatParticipant => {
  return {
    id: p.id,
    clientId: p.clientId,
    clientType: p.clientType,
    username: p.username,
  };
};

type ClientTypeMatchable =
  | Participant
  | ChatParticipant
  | ClientType
  | null
  | undefined;

function buildClientTypeMatchFn(
  target: ClientType | ClientType[]
): (m: ClientTypeMatchable) => boolean {
  return (m: ClientTypeMatchable): boolean => {
    if (!m) return false;
    const srcClientType = typeof m === 'string' ? m : m.clientType;
    if (Array.isArray(target)) {
      return target.includes(srcClientType);
    } else {
      return srcClientType === target;
    }
  };
}

export const ClientTypeUtils = {
  isHost: buildClientTypeMatchFn(ClientType.Host),
  isAudience: buildClientTypeMatchFn(ClientType.Audience),
};

type RoleMatchable = User | Role | undefined;

function buildRoleMatchFn(
  target: Role | Role[]
): (m: RoleMatchable) => boolean {
  return (m: RoleMatchable): boolean => {
    if (!m) return false;
    const role = typeof m === 'number' ? m : m.role;
    if (Array.isArray(target)) {
      return target.includes(role);
    } else {
      return role === target;
    }
  };
}

export const RoleUtils = {
  isAdmin: buildRoleMatchFn(Role.Admin),
  isGeneralUser: buildRoleMatchFn(Role.User),
  parse: (role: string): Role | undefined => {
    switch (role) {
      case 'admin':
      case '10':
        return Role.Admin;
      case 'user':
      case '0':
        return Role.User;
      default:
        return undefined;
    }
  },
  prettyPrint: (role: Role): string => {
    switch (role) {
      case Role.Admin:
        return 'Admin';
      case Role.User:
      default:
        return 'User';
    }
  },
};

const TEAM_STAFF_FLAG = '🎡';
const DEBUG_MODE_FLAG = '💡';

export function hasStaffFlag(username: string | undefined): boolean {
  return username?.includes(TEAM_STAFF_FLAG) ?? false;
}

export function isStaff(p: BaseUser | null | undefined): boolean {
  return p ? !!hasStaffFlag(p.username) : false;
}

export function isNotStaff(p: BaseUser | null | undefined): boolean {
  return !isStaff(p);
}

function appendStaffFlag(username: string): string {
  return `${username.trim()} ${TEAM_STAFF_FLAG}`;
}

export function safelyAppendStaffFlag(user: BaseUser, enabled = false): string {
  const username = user.username;

  if (enabled && !hasStaffFlag(username)) {
    return appendStaffFlag(username);
  }
  return username;
}

export function safelyRemoveStaffFlag(user: BaseUser): string {
  const username = user.username;

  if (hasStaffFlag(username)) {
    return username.replace(TEAM_STAFF_FLAG, '').trim();
  }
  return username;
}

export function hasDebugFlag(p: BaseUser | null | undefined): boolean {
  return p ? p.username.includes(DEBUG_MODE_FLAG) : false;
}

export function displayName(
  p: Nullable<Participant>,
  fallback = 'player'
): string {
  return p?.firstName || p?.username || fallback;
}
