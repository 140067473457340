interface EmitterEventMap {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (...args: any[]) => any;
}

export interface EmitterListener<EvMap extends EmitterEventMap> {
  on<Name extends keyof EvMap>(
    name: Name,
    cb: EvMap[Name],
    opts?: EmitterOptions
  ): () => void;
  off<Name extends keyof EvMap>(
    name: Name,
    cb: EvMap[Name],
    opts?: EmitterOptions
  ): void;
}

export interface EmitterOptions {
  signal?: AbortSignal;
}

export class Emitter<EvMap extends EmitterEventMap>
  implements EmitterListener<EvMap>
{
  listeners: { [E in keyof EvMap]?: Set<EvMap[E]> } = {};

  clear(): void {
    for (const name of Object.keys(this.listeners)) {
      this.listeners[name]?.clear();
      delete this.listeners[name];
    }
  }

  on<Name extends keyof EvMap>(
    name: Name,
    cb: EvMap[Name],
    opts?: EmitterOptions
  ): () => void {
    const l = this.listeners[name] ?? new Set();
    l.add(cb);
    this.listeners[name] = l;
    const off = () => this.off(name, cb);
    opts?.signal?.addEventListener('abort', off, { once: true });
    return off;
  }

  off<Name extends keyof EvMap>(name: Name, cb: EvMap[Name]): void {
    const l = this.listeners[name] ?? new Set();
    l.delete(cb);
    this.listeners[name] = l;
  }

  once<Name extends keyof EvMap>(
    name: Name,
    cb: EvMap[Name],
    opts?: EmitterOptions
  ): () => void {
    const off = this.on(
      name,
      ((...args) => {
        cb(...args);
        off();
      }) as EvMap[Name],
      opts
    );
    return off;
  }

  oncep<Name extends keyof EvMap>(
    name: Name,
    opts?: EmitterOptions
  ): Promise<Parameters<EvMap[Name]>> {
    return new Promise<Parameters<EvMap[Name]>>((resolve) => {
      const handler = ((...args: Parameters<EvMap[Name]>) =>
        resolve(args)) as EvMap[Name];
      this.once(name, handler, opts);
    });
  }

  emit<Name extends keyof EvMap>(
    name: Name,
    ...args: Parameters<EvMap[Name]>
  ): void {
    const l = this.listeners[name] ?? new Set();
    l.forEach((cb) => cb(...args));
    this.listeners[name] = l;
  }
}
