import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { proxy, useSnapshot } from 'valtio';
import { devtools } from 'valtio/utils';

import { type UserAnalytics } from '../analytics/user';
import { AsyncCallState } from '../hooks/useAsyncCall';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { getLogger } from '../logger/logger';
import { apiService } from '../services/api-service';
import { type VirtualBackgroundEffects } from '../services/webrtc/virtual-background';
import { RoleUtils, safelyRemoveStaffFlag, type User } from '../types/user';
import { Emitter } from '../utils/emitter';
import { getToken } from '../utils/getToken';
import { type IStorage, StorageFactory } from '../utils/storage';
import { useToken } from '../utils/token';
import { ValtioUtils } from '../utils/valtio';

const log = getLogger().scoped('user-context');

export type AudioMutedBy = 'user' | 'system' | 'host';

/**
 * _audio_ and _video_ represent the states of two mixed concepts,
 *  1. whether or not the mic/cam is open
 *  2. whether or not the audio/video stream is aviailable
 *
 * At the beginning, they are the same, you open the mic/cam, you get the
 * audio/video stream. But after having AudioBus/CameraVideoMixer (used by host),
 * they need to be separated in some cases. That's why we introduced _micOpen_
 * and _camOpen_ to represent the additional states of the mic/cam.
 *
 */
type AudioVideoStates = {
  audio: boolean;
  video: boolean;
  audioMutedBy?: AudioMutedBy;
  micOpen: boolean;
  camOpen: boolean;
};

type PublicStates = {
  joined: boolean;
  lite: boolean;
  mirror: boolean;
  virtualBackgroundEffects: VirtualBackgroundEffects | null;
} & AudioVideoStates;
type States = {
  loading: AsyncCallState;
} & PublicStates;

interface State {
  user: User;
  states: States;
}

interface UserContext {
  resetUser(): void;
  updateUser(u: Partial<Omit<User, 'id'>>): void;
  updateUserStates(
    s: Partial<Pick<PublicStates, 'micOpen' | 'joined' | 'lite'>>
  ): void;
  postLogin(token: string, u: User): void;
  updateMirror(val: boolean): void;
  toggleAudio(val: boolean, mutedBy?: AudioMutedBy): void;
  toggleVideo(val: boolean, camOpen?: boolean): void;
  updateVirtualBackgroundEffects(val: VirtualBackgroundEffects | null): void;
}

interface InternalUserContext extends UserContext {
  api: UserContextAPI;
}

const Context = React.createContext<InternalUserContext | null>(null);

export function useUserContext(): UserContext {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('No value provided for UserContext');
  return ctx;
}

function useInternalUserContext(): InternalUserContext {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('No value provided for UserContext');
  return ctx;
}

export function useUser(props?: { init?: boolean; token?: string }): User {
  const { init, token } = props || {};
  const { api } = useInternalUserContext();
  const state = api.state;

  useEffect(() => {
    if (
      !init ||
      !!state.user.id ||
      state.states.loading !== AsyncCallState.NotStarted
    )
      return;
    api.initUserFromToken(token);
  }, [init, token, state.user.id, state.states.loading, api]);
  return useSnapshot(state.user) as typeof state.user;
}

export function useUserStates(): States {
  const { api } = useInternalUserContext();
  return useSnapshot(api.state.states);
}

export function useIsUserLoaded(): boolean {
  const states = useUserStates();
  return states.loading === AsyncCallState.Done;
}

export function useUserGetter() {
  const { api } = useInternalUserContext();
  return useCallback(() => api.state.user, [api]);
}

export function isGuest(user: User): boolean {
  return RoleUtils.isGeneralUser(user) && user.id === user.email;
}

export function isOrgMember(user: User): boolean {
  return !!user.organizer;
}

type Events = {
  'audio-toggled': (
    prev: boolean,
    curr: boolean,
    mutedBy?: AudioMutedBy
  ) => void;
};

type MediaSettings = {
  mirror?: boolean;
  virtualBackgroundEffects?: VirtualBackgroundEffects | null;
};

class UserContextAPI {
  private _state = proxy<State>(this.initialState());
  private undevtools = devtools(this._state, { name: 'UserContextAPI' });
  private emitter = new Emitter<Events>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  constructor(
    private storage: IStorage<'mediaSettings', MediaSettings>,
    readonly setToken: (token: string | null) => void,
    readonly getToken: () => string | null,
    readonly analytics: UserAnalytics | undefined
  ) {}

  destroy() {
    this.undevtools?.();
  }

  get state(): Readonly<State> {
    return this._state;
  }

  postLogin(token: string, user: User) {
    this.setToken(token);
    ValtioUtils.update(this._state.user, user);
    apiService.setSecureToken(token);
    this._state.states.loading = AsyncCallState.Done;
    this.analytics?.identify(user);
  }

  async initUserFromToken(token?: string) {
    const userToken = token || getToken();
    if (!userToken) {
      this._state.states.loading = AsyncCallState.Done;
      return;
    }
    apiService.setSecureToken(userToken);
    try {
      this._state.states.loading = AsyncCallState.Running;
      const resp = await apiService.auth.verify();
      this.postLogin(resp.data.token, resp.data.user);
    } catch (err) {
      throw err;
    } finally {
      this._state.states.loading = AsyncCallState.Done;
    }
  }

  toggleAudio(val: boolean, mutedBy?: AudioMutedBy) {
    const prev = this._state.states.audio;
    this._state.states.audio = val;
    if (val) {
      sessionStorage.removeItem('audioMutedBy');
      this._state.states.audioMutedBy = undefined;
    } else {
      if (mutedBy) {
        sessionStorage.setItem('audioMutedBy', mutedBy);
        this._state.states.audioMutedBy = mutedBy;
      } else {
        sessionStorage.removeItem('audioMutedBy');
        this._state.states.audioMutedBy = undefined;
      }
    }
    this.emitter.emit('audio-toggled', prev, val, mutedBy);
  }

  toggleVideo(val: boolean, camOpen?: boolean) {
    ValtioUtils.update(this._state.states, {
      video: val,
      camOpen: camOpen ?? val,
    });
  }

  updateUserStates(
    s: Partial<Pick<PublicStates, 'micOpen' | 'joined' | 'lite'>>
  ) {
    ValtioUtils.update(this._state.states, s);
  }

  updateMirror(val: boolean) {
    ValtioUtils.update(this._state.states, { mirror: val });
    this.persistMediaSettings({ mirror: val });
  }

  updateVirtualBackgroundEffects(val: VirtualBackgroundEffects | null) {
    ValtioUtils.update(this._state.states, { virtualBackgroundEffects: val });
    this.persistMediaSettings({ virtualBackgroundEffects: val });
  }

  private persistMediaSettings(settings: Partial<MediaSettings>) {
    const mediaSettings = { ...this.storage.get('mediaSettings'), ...settings };
    this.storage.set('mediaSettings', mediaSettings);
  }

  updateUser(u: Partial<Omit<User, 'id'>>) {
    ValtioUtils.update(this._state.user, u);
  }

  resetUser() {
    ValtioUtils.reset(this._state, this.initialState());
    this.setToken(null);
    this.analytics?.unidentify();
  }

  private initialState() {
    const mediaSettings = this.storage.get('mediaSettings');
    return {
      user: {
        email: null,
        username: '',
        role: 0,
        id: '',
        venueActivated: false,
        organizer: null,
      },
      states: {
        audio: true,
        micOpen: true,
        video: true,
        camOpen: true,
        joined: false,
        lite: false,
        loading: AsyncCallState.NotStarted,
        mirror: mediaSettings?.mirror ?? true,
        virtualBackgroundEffects:
          mediaSettings?.virtualBackgroundEffects ?? null,
      },
    };
  }
}

export function useUpdateUsername() {
  const { updateUser } = useUserContext();
  return useLiveCallback(async (newName: string) => {
    // Update context, make sure the in-memory user is updated.
    updateUser({ username: newName });
    // Note(jialin): It's fine to only log the error here even if the newName is not persisted.
    // Most likely our team will click the links with ferris-wheel=enabled again next time.
    try {
      await apiService.user.updateUsername(newName);
    } catch (err) {
      log.error('update staff flag failed', err);
    }
  });
}

export function useRemoveStaffFlag() {
  const user = useUser();
  const updateUsername = useUpdateUsername();
  return useLiveCallback(async () => {
    const url = window.location.href;
    const r = new URL(url);
    r.searchParams.delete('ferris-wheel');
    const newUrl = r.href;
    window.history.replaceState(null, '', newUrl);
    const newName = safelyRemoveStaffFlag(user);
    await updateUsername(newName);
  });
}

export function useUserContextAPI() {
  return useInternalUserContext().api;
}

type UserContextProviderProps = {
  children?: ReactNode;
  useUserAnalytics?: () => UserAnalytics | undefined;
  storage?: IStorage<'mediaSettings', MediaSettings>;
};

export const UserContextProvider = ({
  children,
  useUserAnalytics = () => undefined,
  storage,
}: UserContextProviderProps): JSX.Element | null => {
  const analytics = useUserAnalytics();
  const [, setToken] = useToken();
  const api = useMemo(
    () =>
      new UserContextAPI(
        storage ?? StorageFactory<'mediaSettings', MediaSettings>('local'),
        setToken,
        getToken,
        analytics
      ),
    [analytics, setToken, storage]
  );

  useEffect(() => {
    return () => {
      api.destroy();
    };
  }, [api]);

  const ctxValue: InternalUserContext = useMemo(
    () => ({
      api,
      resetUser: api.resetUser.bind(api),
      updateUser: api.updateUser.bind(api),
      updateUserStates: api.updateUserStates.bind(api),
      postLogin: api.postLogin.bind(api),
      updateMirror: api.updateMirror.bind(api),
      toggleAudio: api.toggleAudio.bind(api),
      toggleVideo: api.toggleVideo.bind(api),
      updateVirtualBackgroundEffects:
        api.updateVirtualBackgroundEffects.bind(api),
    }),
    [api]
  );

  return <Context.Provider value={ctxValue}>{children}</Context.Provider>;
};

export function UserRequired(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const user = useUser({ init: true });

  if (!user.id) return null;

  return <>{props.children}</>;
}
